import { React , useState } from 'react';
import { Link } from 'react-router-dom'

import item from '../../assets/fake-data/item';

const About = () => {

    const [data] = useState(
        {
            subtitle: 'About SuperDoge',
            title: 'The Pioneers of the Doginals Movement',
            desc1: 'Crafted with love for $DOGE and a passion for Web3 development, the SuperDoge project aims for greatness. The first set of NFTs is your key to the future of our projects ecosystem. As a team of professional web3 developers and NFT enthusiasts, weare planning lots of exciting stuff for our beloved SuperDoggies.',
            desc2: 'Starting with a set of 5000 rare NFTs on the drc-20 as unique rare web3 collectibles, we plan to harness the power of the Doge community to further develop the protocol and its applications in the sphere of web3 and the real world. Apart from being unique collectibles, SuperDoge NFTs are the first step towards blockchain-based gaming, airdrops, real-world use cases, and an awesome community of creative, like-minded individuals.',
        }
    )

    return (
        <section id="section-about1" className="tf-section section-about">
            <div className="container">
                <div className="row reverse">
                    <div className="col-xl-7 col-md-12">
                        <div className="group-image">
                            <div className="left">
                                <div className="item bg-1"><img src={item.item8} alt="SuperDoge" /></div>
                            </div>
                            <div className="right">
                                <div className="item bg-2"><img src={item.item9} alt="SuperDoge" /></div>
                                <div className="item bg-3"><img src={item.item10} alt="SuperDoge" /></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-md-12">
                        <div className="block-text pt-12">
                            <h5 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">{data.subtitle}</h5>
                            <h3 className="title mb-58" data-aos="fade-up" data-aos-duration="1000">{data.title}</h3>
                            <p className="fs-21 mb-33" data-aos="fade-up" data-aos-duration="1000">{data.desc1}</p>
                            <p className="fs-21 mb-33" data-aos="fade-up" data-aos-duration="1000">{data.desc2}</p>
                            <Link to="https://twitter.com/SuperDogeNFT" className="btn-action style-2"  data-aos="fade-up" data-aos-duration="1200">FOLLOW SUPERDOGE</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
const dataFaq = [
    {
        title: 'What is the SuperDoge NFT Project?',
        text: 'SuperDoge stands as a unique meme NFT project and a web-3 collectible series on the Dogecoin blockchain. This project seamlessly blends the iconic DOGE meme with modern meme culture, presenting a collection of digital collectibles that epitomize fun and engagement.',
        show: 'true'
    },
    {
        title: 'What makes SuperDoge NFTs unique?',
        text: 'Each SuperDoge NFT is a one-of-a-kind digital collectible featuring superhero-themed DOGE characters. They are not just art; they are part of a larger community and movement in the Dogecoin ecosystem.',

    },
    {
        title: 'How can I purchase a SuperDoge NFT?',
        text: 'You can purchase SuperDoge NFTs through the drc-20.org official website. First, you will need to have a DOGE Labs wallet with some $DOGE in it. Then, visit the NFT marketplace, choose your favorite SuperDoge, and make your purchase.'
    },
    {
        title: 'What is the total supply of SuperDoge NFTs?',
        text: 'The SuperDoge collection is limited to 5000 unique NFTs. This limited supply ensures exclusivity and potential value appreciation for collectors.'
    },
    {
        title: 'Can I resell my SuperDoge NFT?',
        text: 'Yes, you can resell your SuperDoge NFT on any compatible NFT marketplace. The value may vary based on demand and rarity.'
    },
    {
        title: 'What wallet do I need to store my SuperDoge NFT?',
        text: 'You will need a DOGE-compatible wallet that supports drc-20 tokens. We recommend using the DOGE Labs wallet for the best experience.'
    },
    {
        title: 'What is the drc-20 Standard?',
        text: 'The drc-20 standard is a technical standard used for creating tokens within the Dogecoin blockchain ecosystem. Similar to Ethereum ERC-20, the drc-20 standard defines a set of rules that a Dogecoin token must follow, ensuring compatibility and functionality within the broader Dogecoin network. This standard allows for the development of fungible tokens, like those used in the SuperDoge NFT project, enabling seamless transactions, interoperability with various services, and integration into digital wallets and marketplaces that support the Dogecoin ecosystem.'
    },
    {
        title: 'Are there any future plans for SuperDoge holders?',
        text: 'While we have many exciting ideas, our immediate focus is on community building and enhancing the value of SuperDoge NFTs. Keep an eye on our official channels for future announcements.'
    },
    {
        title: 'How does SuperDoge benefit the DOGE community?',
        text: 'SuperDoge is more than an NFT project; it is a movement that contributes to the DOGE ecosystem. It not only introduces innovative use-cases for DOGE but also brings together a community of DOGE enthusiasts and NFT collectors.'
    },
    {
        title: 'How can I stay updated on SuperDoge news and events?',
        text: 'The best way to stay informed is by following our official social media channels and subscribing to our newsletter. We regularly post updates, news, and upcoming events related to SuperDoge.'
    },
]

export default dataFaq;
const dataRoadMap = [
    {
        title: 'SuperDoge Idea Generation (Completed)',
        desc: 'The idea was always there – what could be better than a superhero DOGE? A concept rooted in fun and heroism, perfectly blending with the spirit of meme culture.',
        class: 'left'
    },
    {
        title: '$SDOG Design & Development (Completed)',
        desc: 'Crafting $SDOG involved selecting the ideal artist, defining the right style, and capturing the essence of meme culture. This stage also encompassed the creation of a website and laying out plans for the future.',
        class: 'right mt-223'
    },
    {
        title: 'DogeLabs Networking and Collaboration',
        desc: 'Currently, our focus is networking with key players like DogeLabs. This essential step will pave the way for future collaborations and opportunities.',
        class: 'left mt--23'
    },
    {
        title: 'SuperDoge NFT Collection Drop',
        desc: 'Who let the SuperDoge out? Woof, Woof, Woof-Woof! Get ready for the exciting launch of the SuperDoge NFT collection.',
        class: 'right mt-200'
    },
    {
        title: 'Community Building',
        desc: 'The strength of any NFT project lies in its community. We are committed to building a strong, engaged community, especially in the world of ever-growing love for meme coins and NFTs.',
        class: 'left mt--23'
    },
    {
        time: 'Q1, 2024',
        title: 'The Airdrop… (Coming Soon)',
        desc: 'Tsss… It is still a secret. Keep an eye out for this exciting, mysterious part of our roadmap.',
        class: 'right mt-200'
    },
    {
        title: 'NFT Holders Utility Unlock',
        desc: 'Did someone say more SuperDoge? Think token utility, potential breeding, and more – exciting times ahead for NFT holders!',
        class: 'left mt--23'
    },
]

export default dataRoadMap;
import img1 from '../images/icon/wallet.png';
import img2 from '../images/icon/shopping-cart.png';
import img3 from '../images/icon/folder.png';
import img4 from '../images/icon/money-bag.png';

const dataPortfolio = [
    {
        img: img1,
        step:'Step 01',
        title : <a href="https://drc-20.org" target="_blank" rel="noopener noreferrer">Visit drc-20.org</a>,
        desc: 'Become a pioneer of the Doginals movement. Visit the cradle of the drc-20, download and install the DOGE Labs wallet, and throw some $DOGE in there to join the party.'
    },
    {
        img: img2,
        step:'Step 02',
        title : 'Find SuperDoge',
        desc: 'Get into the NFT list and search for $SDOGE (SuperDoge). Have a look at the cutest dogs and get one for yourself. Check out the minting page or even the launchpad if we are lucky!'
    },
    {
        img: img3,
        step:'Step 03',
        title : 'Own SuperDoge',
        desc: 'Now you have it, the Doginal inscription, a unique and rare piece of DOGE history. HODL it, join the community, check out the news on Twitter, and enjoy the party.'
    },
    {
        img: img4,
        step:'Step 04',
        title : 'The Airdrop',
        desc: 'There is a rumor about an airdrop… Stay tuned and you might be rewarded. But for now, keep it a secret. You will know for sure closer to the Mating Season!'
    },
]

export default dataPortfolio;
import React from 'react';

const Newsletter = () => {
    const data = {
        title: 'SuperDoge Newsletter',
        desc: 'Stay connected with SuperDoge Farm and don’t miss any updates'
    };

    // Mailchimp form submission URL
    const mailChimpUrl = "https://superdoge.us21.list-manage.com/subscribe/post?u=f21efc0d857728cb745bfff7f&id=5f77f06777";

    return (
        <section className="tf-section newsletter">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="newsletter__body" data-aos="fade-up">
                            <div className="block-text">
                                <h3 className="fs-25">{data.title}</h3>
                                <p className="fs-21">{data.desc}</p>
                            </div>
                            <form 
                                action={mailChimpUrl} 
                                method="post" 
                                id="mc-embedded-subscribe-form" 
                                name="mc-embedded-subscribe-form" 
                                className="validate" 
                                target="_blank"
                            >
                                <div id="mc_embed_signup_scroll">
                                    <div className="form-group">
                                        <input type="email" name="EMAIL" className="required email form-control" id="mce-EMAIL" placeholder="Your email here" required />
                                        <button type="submit" name="subscribe" id="mc-embedded-subscribe" className="btn btn-primary">Subscribe</button>
                                    </div>
                                    {/* Add any additional Mailchimp fields here */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Newsletter;

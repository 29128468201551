import React from 'react';
import { Link } from 'react-router-dom';

import './button.scss';

const ButtonOne = () => {
  return (
        <Link to='https://linktr.ee/superdogenft' target="_blank" rel="noopener noreferrer" className="btn-action">
            Join Now
        </Link>
  );
}

export default ButtonOne;
import React , { useState ,useEffect } from 'react';
import { Link } from 'react-router-dom';
import './footer.scss';

import logo2 from '../../assets/fake-data/logo2';

const Footer = () => {

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <>
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="footer__body">
                      <Link to="/">
                        <img src={logo2} alt="SuperDoge" data-aos="fade-down" />
                      </Link>
                        
                        <p className="desc fs-18" data-aos="fade-up">
                        Our ambitious roadmap and plans rely on DRC-20 technology which still have some limitations.  We are wholeheartedly committed to bringing the absolute best version of this project to life, and your support is absolutely crucial in making that happen. It's important to emphasize that each SuperDoge NFT is a digital art asset; it's a piece of unique, cute, and fun meme culture. These NFTs are designed to be cherished collectibles on the Dogecoin blockchain, representing the essence of our shared journey. Thank you for being an essential part of our SuperDoge family as we make history together! 
                        </p>
                        <ul className="social">
                            <li data-aos="fade-up" data-aos-duration="1200"><Link to="https://twitter.com/SuperDogeNFT"><i className="fab fa-twitter"></i></Link></li>
                            <li data-aos="fade-up" data-aos-duration="1400"><Link to="www.linkedin.com/in/superdoge"><i className="fab fa-linkedin-in"></i></Link></li>
                            <li data-aos="fade-up" data-aos-duration="1400"><Link to="https://t.me/SuperDogeOrg"><i className="fab fa-telegram-plane"></i></Link></li>
                        </ul>
                    </div>
                    <div className="footer_bottom">
                        <p className="fs-16">Copyright © 2024, SuperDoge</p>
                        
                    </div>
                </div>
            </div>
        </footer>
        {
            isVisible && 
            <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
        }</>
        
    );
}

export default Footer;
import img1 from '../images/icon/hosting.png'
import img2 from '../images/icon/provision.png'
import img3 from '../images/icon/badge.png'
import img4 from '../images/icon/chat.png'

const dataCard = [
    {
        stt: '01',
        title:'Limited Availability',
        desc: 'With only 5000 SuperDoge NFTs.',
        class: 'mr-28',

        class2: '',
        img: img1
    },
    {
        stt: '02',
        title:'High Quality',
        desc: 'Everything about $SDOGE is of the highest quality.',
        class: 'mt-45',

        class2: 's2',
        img: img2
    },
    {
        stt: '03',
        title:'DOGE Community',
        desc: 'Since the dawn of Dogecoin, we’ve all been a part of it.',
        class: 'mr-28 mt--16',

        class2: 's2-m',
        img: img3
    },
    {
        stt: '04',
        title:'The Future',
        desc: 'The future of SuperDoge is still a mystery.',
        class: 'mt-29',

        class2: 's2',
        img: img4
    },
]

export default dataCard;
const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
       
    },
    {
        id: 2,
        name: 'About',
        links: '#section-about1'
    },{
        id: 3,
        name: 'Collections',
        links: '#collection1'
    },
    {
        id: 4,
        name: 'RoadMap',
        links: '#roadmap1',
       
    },
    {
        id: 5,
        name: 'Help',
        links: '#faq'
    },
    
]

export default menus;